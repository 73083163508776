<script>
import MyCourses from "../../layouts/my-courses.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


export default {
  page: {
    title: "ระบบหลัก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    "my-courses" : MyCourses,
    PageHeader,
  },
  data() {
    return {
      title: "ระบบหลัก",
      items: [
        {
          text: "ShowShare",
        },
        {
          text: "ลงทะเบียนครูแพทย์แผนไทย",
          active: true,
        },
      ],
      currentUser: {},
      
    };
  },
  computed: {},
  methods: {
    async getLatestUserInfo() {
      console.log("================");
      console.log("Load New Data");
      const user = JSON.parse(localStorage.getItem('auth.user')) || {};
      console.log("user: user")
      if (user == {}) {
        return false;
      }
      const resp = await this.$store.dispatch("apiRestful/getLatestUserInfo", {
        uid: user.uid,
      }).catch((error) => {
        console.log("error:", error);
        return error;
      });
      if (resp) {
        localStorage.setItem('auth.user', JSON.stringify(resp));
      }
      console.log("================");
      return true;
    }
  },
};
</script>

<template>
  <my-courses v-on:reloadUserData="getLatestUserInfo">
    <PageHeader :title="title" :items="items" />
  </my-courses>
</template>