<script>
import ax from '../helpers/restful/service';
import axios from 'axios';

import { required, numeric } from "vuelidate/lib/validators";
import AlertModal from "@/components/alert-modal.vue";
import ConfirmModal from "@/components/confirm-modal.vue";

export default {
    props: {
        course_id: {
            type: Number,
            required: true,
        },
        reloadFunction: {
            type: Function,
            required: true,
        }
    },
    components: {
        "alert-modal": AlertModal,
        "confirm-modal": ConfirmModal,
    },
    data() {
        return {
            uid: JSON.parse(localStorage.getItem('auth.user')).uid,
            showModal: false,
            showConfirmModal: false,
            showReceiptModal: false,
            showSaveDraftCourseSuccessModal: false,
            showSaveDraftCourseFailedModal: false,
            showDeleteCourseModal: false,
            showDeleteCourseSuccessModal: false,
            showDeleteCourseFailedModal: false,

            showLearningModal: false,
            showPdfAddModal: false,
            newPdf: {
                file_url: null,
                file_name: null,
            },
            
            showImageAddModal: false,
            newImage: {
                file_url: null,
                file_name: null,
            },
            
            showVideoAddModal: false,
            newVideo: {
                file_url: null,
                file_name: null,
            },

            showExampleModal: false,
            newExample: {
                time_test: null,
                amount_test: null,
                percent_pass: null,
                test: [],
            },
            newExampleTest: {
                question: null,
                question_image: null,
                correct_answers: [],
                incorrect_answers: [],
            },
            showExampleAddModal: false,
            showCheckExampleTestDataModal: false,
            temp_correct_choice: null,
            temp_incorrect_choice: null,

            showWarningInspect: false,

            showLoaderModal: false,

            loaded: false,
            course_data: {
                course_id: null,
                course_title: null,
                uid: null,
                open_register_date: null,
                close_register_date: null,
                open_course_date: null,
                close_course_date: null,
                amount_hour: null,
                rate_installment_pay: null,
                rate_full_pay_non: null,
                rate_full_pay: null,
                advertise_percent: null,
                register_property_text: null,
                register_privilege_text: null,
                is_prefer_to_join: null,
                content_detail_pdf: null,
                content_description_text: null,
                content_meeting_link: null,
                content_link: null,
                content_link_text: null,
                course_homework_link: null,
                course_homework_link_text: null,
                course_question_link: null,
                course_question_link_text: null,
                content_json: {},
                exam_json: {
                    time_test: null,
                    amount_test: null,
                    percent_pass: null,
                    test: [],
                },
                course_status: 0,
                created_dt: null,
                approved_dt: null,
                closed_dt: null,
                comment: null,
            },

            // input temp
            open_register_date: null,
            close_register_date: null,
            open_course_date: null,
            close_course_date: null,

            template_course_data: {
                course_id: null,
                course_title: null,
                uid: null,
                open_register_date: null,
                close_register_date: null,
                open_course_date: null,
                close_course_date: null,
                amount_hour: null,
                rate_installment_pay: null,
                rate_full_pay_non: null,
                rate_full_pay: null,
                advertise_percent: null,
                register_property_text: null,
                register_privilege_text: null,
                is_prefer_to_join: null,
                content_detail_pdf: null,
                content_description_text: null,
                content_meeting_link: null,
                content_link: null,
                content_link_text: null,
                course_homework_link: null,
                course_homework_link_text: null,
                course_question_link: null,
                course_question_link_text: null,
                content_json: {},
                exam_json: {
                    time_test: null,
                    amount_test: null,
                    percent_pass: null,
                    test: [],
                },
                course_status: 0,
                created_dt: null,
                approved_dt: null,
                closed_dt: null,
                comment: null,
            },
            link_pass_all: false,
            submitted: false,

            checkCourseDataModal: false,
        }
    },
    validations: {
        course_data: {
            course_title: {
                required,
            },
            open_register_date: {
                required,
            },
            close_register_date: {
                required,
            },
            open_course_date: {
                required,
            },
            close_course_date: {
                required,
            },
            amount_hour: {
                required,
                numeric,
            },
            rate_installment_pay: {
                required,
                numeric,
            },
            rate_full_pay_non: {
                required,
                numeric,
            },
            rate_full_pay: {
                required,
                numeric,
            },
            advertise_percent: {
                required,
                numeric,
            },
            register_property_text: {
                required,
            },
            register_privilege_text: {
                required,
            },
            is_prefer_to_join: {
                required,
            },
            content_detail_pdf: {
                required,
            },
            content_description_text: {
                required,
            },
            content_meeting_link: {
                required,
            },
        },
    },
    mounted() {
    },
    created() {
        this.getCourseInfo();
    },
    watch:{
        open_register_date: {
            handler(n){
                if(n == null || n == "1970-01-01" || n == "1970-1-1" || n == "") {
                    this.course_data.open_register_date = null;
                    return;
                }
                n.split("-").map(e => e == 1 ? e.padStart(2, "0") : e);
                const date = new Date(n);
                this.course_data.open_register_date = date;
            }
        },
        close_register_date: {
            handler(n){
                if(n == null || n == "1970-01-01" || n == "1970-1-1" || n == "") {
                    this.course_data.close_register_date = null;
                    return;
                }
                n.split("-").map(e => e == 1 ? e.padStart(2, "0") : e);
                const date = new Date(n);
                this.course_data.close_register_date = date;
            }
        },
        open_course_date: {
            handler(n){
                if(n == null || n == "1970-01-01" || n == "1970-1-1" || n == "") {
                    this.course_data.open_course_date = null;
                    return;
                }
                n.split("-").map(e => e == 1 ? e.padStart(2, "0") : e);
                const date = new Date(n);
                this.course_data.open_course_date = date;
            }
        },
        close_course_date: {
            handler(n){
                console.log("nnnnnnnnnnnnnnn:", n);
                if(n == null || n == "1970-01-01" || n == "1970-1-1" || n == "") {
                    this.course_data.close_course_date = null;
                    return;
                }
                n.split("-").map(e => e == 1 ? e.padStart(2, "0") : e);
                const date = new Date(n);
                this.course_data.close_course_date = date;
            }
        },
        course_data: {
            handler(n){
                console.log("new:", n);

                if(n.is_prefer_to_join){
                    this.course_data.is_prefer_to_join = 1;
                } else {
                    this.course_data.is_prefer_to_join = 0;
                }

                if(n.content_link != null && n.content_link != ""){
                    try {
                        new URL(n.content_link);
                    } catch (_) {
                        this.link_pass_all = false;
                        return;
                    }
                }
                
                if(n.content_meeting_link != null && n.content_meeting_link != ""){
                    try {
                        new URL(n.content_meeting_link);
                    } catch (_) {
                        this.link_pass_all = false;
                        return;
                    }
                }
                
                if(n.course_homework_link != null && n.course_homework_link != ""){
                    try {
                        new URL(n.course_homework_link);
                    } catch (_) {
                        this.link_pass_all = false;
                        return;
                    }
                }
                
                if(n.course_question_link != null && n.course_question_link != ""){
                    try {
                        new URL(n.course_question_link);
                    } catch (_) {
                        this.link_pass_all = false;
                        return;
                    }
                }
                this.link_pass_all = true;
            },
            deep: true,
        },
    },
    methods: {
        async fileUpload(event, type = "image"){
            console.log(event);
            this.showLoaderModal = true;
            const [file] = event.target.files;
            const form = new FormData();
            form.append("file", file);
            let url = "/attachment-file";
            if(type == "pdf"){
                url = "/upload-pdf-file";
            }
            const resp = await ax.postForm(url, form);
            console.log("resp fileupload:", resp);
            this.showLoaderModal = false;
            if (resp.file_name) return resp.file_name;
            return null;
        },
        async onFileLearningSelected(event){
            console.log("onFileLearningSelected");
            const filename = await this.fileUpload(event, "pdf");
            if(filename){
                this.course_data.content_detail_pdf = filename;
            }
            return;
        },
        async onFileLearningCourseSelected(event){
            console.log("onFileLearningCourseSelected");
            const filename = await this.fileUpload(event, "pdf");
            if(filename){
                this.newPdf.file_url = filename;
            }
            return;
        },
        
        async onImageFileLearningCourseSelected(event){
            console.log("onImageFileLearningCourseSelected");
            const filename = await this.fileUpload(event, "image");
            if(filename){
                this.newImage.file_url = filename;
            }
            return;
        },
        
        async onFileExampleImageSelected(event){
            console.log("onFileExampleImageSelected");
            const filename = await this.fileUpload(event, "image");
            if(filename){
                this.newExample.question_image = filename;
            }
            return;
        },

        async uploadVideo(event){
            this.showLoaderModal = true;
            console.log(event);
            const [file] = event.target.files;
            const form = new FormData();
            form.append("file", file);
            form.append("submit", true);
            const axVideos = axios.create({
                baseURL: "https://thaimed-videos.v2vservice.com",
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                },
            });
            
            const resp = await axVideos.postForm("/uploader.php?"+Date.now(), form);

            this.showLoaderModal = false;
            console.log("resp fileupload:", resp);
            if (resp.data.video_path) return resp.data.video_path;
            return null;
        },

        async onVideoFileLearningCourseSelected(event){
            console.log("onVideoFileLearningCourseSelected");
            const filename = await this.uploadVideo(event, "video");
            if(filename){
                this.newVideo.file_url = filename;
            }
            return;
        },

        async getCourseInfo() {
            this.showLoaderModal = true;
            const resp = await ax.post("/get-course-by-course-id", {
                uid: this.uid,
                course_id: this.course_id,
            });

            if (resp.status != 200) {
                console.info("Can not load course_id:", this.course_id);
                return;
            }

            console.log("resp.data.course_data:", resp.data.course_data);

            await Promise.all([
                this.course_data = resp.data.course_data,
            ]);

            console.log(this.course_data);

            // init field
            if(this.course_data.content_json == null){
                this.course_data.content_json = {
                    videos: [],
                    pdf: [],
                    images: [],
                }
            }

            if(this.course_data.content_json?.pdf == undefined){
                this.course_data.content_json = {
                    ...this.course_data.content_json,
                    pdf: [],
                }
            }
            if(this.course_data.content_json?.videos == undefined){
                this.course_data.content_json = {
                    ...this.course_data.content_json,
                    videos: [],
                }
            }
            if(this.course_data.content_json?.images == undefined){
                this.course_data.content_json = {
                    ...this.course_data.content_json,
                    images: [],
                }
            }

            if(this.course_data.exam_json == null){
                this.course_data.exam_json = {
                    time_test: null,
                    amount_test: null,
                    percent_pass: null,
                    test: [],
                }
            }

            if(this.course_data.exam_json.time_test == undefined){
                this.course_data.exam_json = {
                    ...this.course_data.exam_json,
                    time_test: null,
                }
            }
            
            if(this.course_data.exam_json.amount_test == undefined){
                this.course_data.exam_json = {
                    ...this.course_data.exam_json,
                    amount_test: null,
                }
            }

            if(this.course_data.exam_json.percent_pass == undefined){
                this.course_data.exam_json = {
                    ...this.course_data.exam_json,
                    percent_pass: null,
                }
            } 

            if(this.course_data.exam_json.test == undefined){
                this.course_data.exam_json = {
                    ...this.course_data.exam_json,
                    test: [],
                }
            } 
            this.open_register_date = this.convertToDateInput(resp.data.course_data.open_register_date);
            this.close_register_date = this.convertToDateInput(resp.data.course_data.close_register_date);
            this.open_course_date = this.convertToDateInput(resp.data.course_data.open_course_date);
            this.close_course_date = this.convertToDateInput(resp.data.course_data.close_course_date);
            
            console.log("course_data in cfb:", this.course_data);

            this.showLoaderModal = false;
            this.loaded = true;
            return;
        },

        async deleteCourse() {
            this.showLoaderModal = true;
            const resp = await ax.post("/delete-course", {
                uid: this.uid,
                course_id: this.course_id,
            });

            this.showLoaderModal = false;

            if (resp.status == 200) {
                this.showDeleteCourseModal = false;
                this.showDeleteCourseSuccessModal = true;
                await this.reloadFunction();
                return;
            }
            this.showDeleteCourseFailedModal = true;
            return;
        },

        sendCourseDataToInspect() {
            if(
                this.course_data.course_title == null 
                || this.course_data.course_title == ""
                || this.course_data.content_description_text == null 
                || this.course_data.content_description_text == ""
                || this.course_data.open_register_date == null 
                || this.course_data.open_register_date == ""
                || this.course_data.close_register_date == null 
                || this.course_data.close_register_date == ""
                || this.course_data.open_course_date == null 
                || this.course_data.open_course_date == ""
                || this.course_data.close_course_date == null 
                || this.course_data.close_course_date == ""
                || this.course_data.amount_hour == null 
                || this.course_data.amount_hour == ""
                || this.course_data.rate_installment_pay == null 
                || this.course_data.rate_installment_pay == ""
                || this.course_data.rate_full_pay == null 
                || this.course_data.rate_full_pay == ""
                || this.course_data.rate_full_pay_non == null 
                || this.course_data.rate_full_pay_non == ""
                || this.course_data.advertise_percent == null 
                || this.course_data.advertise_percent == ""
                || this.course_data.register_property_text == null 
                || this.course_data.register_property_text == ""
                || this.course_data.register_privilege_text == null 
                || this.course_data.register_privilege_text == ""
                || this.course_data.is_prefer_to_join == null 
                || this.course_data.is_prefer_to_join == ""
                || this.course_data.content_detail_pdf == null 
                || this.course_data.content_detail_pdf == ""
                || (this.course_data.content_json?.pdf.length + this.course_data.content_json?.images.length + this.course_data.content_json?.videos.length == 0) 
                || this.course_data.exam_json?.time_test == null 
                || this.course_data.exam_json?.time_test == "" 
                || this.course_data.exam_json?.amount_test == null 
                || this.course_data.exam_json?.amount_test == "" 
                || this.course_data.exam_json?.amount_test == 0 
                || this.course_data.exam_json?.percent_pass == null 
                || this.course_data.exam_json?.percent_pass == "" 
                || parseInt(this.course_data.exam_json?.percent_pass) > 100 
                || parseInt(this.course_data.exam_json?.percent_pass) < 0 
                || (this.course_data.exam_json?.test.length == 0)
                || !this.link_pass_all
            ){
                this.checkCourseDataModal = true;
                return;
            }

            this.showWarningInspect = true;
            return;
        },

        async confirmToSendInspect() {
            this.showLoaderModal = true;
            this.showWarningInspect = false;
            const resp = await ax.post("/send-to-inspect-course", {
                uid: this.uid,
                course_id: this.course_id,
            });
            
            if(resp.data.is_updated){
                this.showLoaderModal = false;
                await this.reloadFunction();
                return;
            }
        },

        async tryToSaveNewCourse() {
            this.showLoaderModal = true;
            const resp = await ax.post("/save-new-course", {
                course_data: this.course_data,
            });
            
            this.showLoaderModal = false;

            if (resp.status == 200) {
                this.showSaveNewCourseSuccessModal = true;
                return;
            }
            this.showSaveNewCourseFailedModal = true;
            return;
        },

        async updateLearningFile(){
            this.course_data.content_json.pdf.push(this.newPdf);
            this.newPdf = {
                file_name: null,
                file_url: null,
            };
            this.showPdfAddModal = false;
            await this.updateCourseContentJson();
        },
        
        async updateLearningImageFile(){
            this.course_data.content_json.images.push(this.newImage);
            this.newImage = {
                file_name: null,
                file_url: null,
            };
            this.showImageAddModal = false;
            await this.updateCourseContentJson();
        },
        
        async updateLearningVideoFile(){ 
            this.course_data.content_json.videos.push(this.newVideo);
            this.newVideo = {
                file_name: null,
                file_url: null,
            };
            this.showVideoAddModal = false;
            await this.updateCourseContentJson();
        },
        
        async updateExampleTest(){
            
            if(this.newExampleTest.question.length == 0){
                this.showCheckExampleTestDataModal = true;
                return;
            }

            if(this.newExampleTest.correct_answers.length == 0){
                this.showCheckExampleTestDataModal = true;
                return;
            }

            if(this.newExampleTest.incorrect_answers.length == 0){
                this.showCheckExampleTestDataModal = true;
                return;
            }

            if(this.newExampleTest.correct_answers.length + this.newExampleTest.incorrect_answers.length < 4){
                this.showCheckExampleTestDataModal = true;
                return;
            }

            this.course_data.exam_json.test.push(this.newExampleTest);
            this.newExampleTest = {
                question: null,
                question_image: null,
                correct_answers: [],
                incorrect_answers: [],
            };
            this.showExampleAddModal = false;
            await this.updateCourseExampleJson();
        },
        
        async updateCourse() {
            this.showLoaderModal = true;
            const resp = await ax.post("/update-course", {
                uid: this.uid,
                course_id: this.course_id,
                course_data: this.course_data,
            });

            this.showModal = false;
            this.showLearningModal = false;
            this.showLoaderModal = false;

            if (resp.status == 200) {
                this.showSaveDraftCourseSuccessModal = true;
                return;
            }
            this.showSaveDraftCourseSuccessModal = true;
            return;
        },

        async updateCourseContentJson() {
            this.showLoaderModal = true;
            const resp = await ax.post("/update-course-content-json", {
                uid: this.uid,
                course_id: this.course_id,
                content_json: this.course_data.content_json,
            });

            this.showModal = false;
            this.showLoaderModal = false;
            
            if (resp.status == 200) {
                this.showSaveDraftCourseSuccessModal = true;
                return;
            }
            this.showSaveDraftCourseSuccessModal = true;
            return;
        },
        
        async updateCourseExampleJson() {
            this.showLoaderModal = true;
            const resp = await ax.post("/update-course-example-json", {
                uid: this.uid,
                course_id: this.course_id,
                exam_json: this.course_data.exam_json,
            });

            this.showLoaderModal = false;
            this.showModal = false;
            
            if (resp.status == 200) {
                this.showSaveDraftCourseSuccessModal = true;
                return;
            }
            this.showSaveDraftCourseSuccessModal = true;
            return;
        },
        
        async deleteContentPdf(i) {
            this.course_data.content_json.pdf.splice(i, 1);

            await this.updateCourseContentJson();
        },

        async swapContentPdf(ref, replace_ref) {
            const temp = this.course_data.content_json.pdf[ref];
            this.course_data.content_json.pdf[ref] = this.course_data.content_json.pdf[replace_ref];
            this.course_data.content_json.pdf[replace_ref] = temp;

            await this.updateCourseContentJson();
            return;
        },
        
        async deleteContentImage(i) {
            this.course_data.content_json.images.splice(i, 1);

            await this.updateCourseContentJson();
        },
        
        
        async swapContentImage(ref, replace_ref) {
            const temp = this.course_data.content_json.images[ref];
            this.course_data.content_json.images[ref] = this.course_data.content_json.images[replace_ref];
            this.course_data.content_json.images[replace_ref] = temp;
            
            await this.updateCourseContentJson();
            return;
        },
        
        async deleteContentVideo(i) {
            this.course_data.content_json.videos.splice(i, 1);
            
            await this.updateCourseContentJson();
        },
        
        async swapContentVideo(ref, replace_ref) {
            const temp = this.course_data.content_json.videos[ref];
            this.course_data.content_json.videos[ref] = this.course_data.content_json.videos[replace_ref];
            this.course_data.content_json.videos[replace_ref] = temp;
            
            await this.updateCourseContentJson();
            return;
        },
        
        async deleteCorrectChoice(i) {
            this.newExampleTest.correct_answers.splice(i, 1);
            return;
        },
        
        async deleteIncorrectChoice(i) {
            this.newExampleTest.incorrect_answers.splice(i, 1);
            return;
        },

        async deleteExample(i) {
            this.course_data.exam_json.test.splice(i, 1);
            
            await this.updateCourseExampleJson();
        },

        async updateExampleInfo() {
            console.log(this.course_data);
            await this.updateCourseExampleJson();
            this.showExampleModal = false;

        },

        convertToDateInput(date_string = "") {
            const d = new Date(date_string);
            return d.getFullYear() + "-" + (d.getMonth()+1).toString().padStart(2, "0") + "-" + d.getDate().toString().padStart(2, "0");
        },

        inputOnlyNumber(evt) {
            evt = (evt) ? evt : window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;

            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                return evt.preventDefault();
            } 
            return true;
        },
    },
};
</script>

<template>
    <div>
        <div v-if="loaded" class="col-12">
            <div class="bg-black py-2 px-2">
                <h5 class="text-white">
                    {{ course_data.course_status == 0 ? "ฉบับร่าง :" : "" }} 
                    {{ course_data.course_status == 1 ? "อยู่ระหว่างส่งตรวจพิจารณา :" : "" }} 
                    {{ course_data.course_status == 2 ? "กำลังใช้งาน :" : "" }} {{ course_data.course_title
                    ?? "{ ยังไม่ได้ระบุชื่อ }" }}</h5>
            </div>
            <div class="mt-3 d-block g-grid text-end">
                <button class="my-1 btn btn-lg btn-block btn-info" @click="showModal = true" type="button" :disabled="course_data.course_status != 0">
                    <span style="font-size: 20px;">
                        แก้ไขรายละเอียดหลักสูตร
                    </span>
                </button>
                <button class="my-1 ms-2 btn btn-lg btn-block btn-info" @click="showLearningModal = true" type="button" :disabled="course_data.course_status != 0">
                    <span style="font-size: 20px;">
                        แก้ไขเนื้อหาอบรม
                    </span>
                </button>
                <button class="my-1 ms-2 btn btn-lg btn-block btn-info" @click="showExampleModal = true" type="button" :disabled="course_data.course_status != 0">
                    <span style="font-size: 20px;">
                        แก้ไขข้อสอบ
                    </span>
                </button>

                
                <button class="my-1 ms-5 btn btn-lg btn-block btn-danger" @click="showDeleteCourseModal = true" type="button" :disabled="course_data.course_status != 0">
                    <span style="font-size: 20px;">
                        ลบ
                    </span>
                </button>
                
                <button v-if="course_data.course_status == 0" @click="sendCourseDataToInspect" class="my-1 ms-2 btn btn-lg btn-block btn-primary" type="button">
                    <span style="font-size: 20px;">
                        ส่งตรวจสอบ
                    </span>
                </button>

            </div>
        </div>

        <b-modal size="xl" v-model="showModal" :title="course_data.course_title" no-close-on-esc no-close-on-backdrop
            hide-header-close scrollable centered>
            <h4 class="text-warning">แบบยื่นคำขอเปิด การอบรม/ทดสอบ</h4>
            <div class="mt-2">
                <span class="fs-5 text text-primary">ชื่อหลักสูตร</span><br />
                <input class="form-control" v-model="course_data.course_title" />
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">กำหนดเปิด ลงทะเบียน</span><br />
                <input type="date" class="form-control" v-model="open_register_date" />
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">กำหนดปิด ลงทะเบียน</span><br />
                <input type="date" class="form-control" v-model="close_register_date" />
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">กำหนดเปิด การอบรม/ทดสอบ</span><br />
                <input type="date" class="form-control" v-model="open_course_date" />
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">กำหนดปิด การอบรม/ทดสอบ</span><br />
                <input type="date" class="form-control" v-model="close_course_date" />
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">จำนวนหน่วยกิจ (หน่วย)</span><br />
                <div class="input-group" role="group">
                    <input class="form-control" v-model="course_data.amount_hour" @keypress="inputOnlyNumber" @input="inputOnlyNumber"/>
                    <span class="input-group-text">หน่วย</span>
                </div>
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">กำหนดอัตราลงทะเบียน สำหรับผ่อนชำระ 10 เดือน (บาท)</span><br />
                <div class="input-group" role="group">
                    <input class="form-control" v-model="course_data.rate_installment_pay" @keypress="inputOnlyNumber" @input="inputOnlyNumber"/>
                    <span class="input-group-text">บาท</span>
                </div>
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">กำหนดอัตราลงทะเบียน สำหรับชำระเต็มจำนวน โดย
                    ผู้ไม่มีใบประกอบวิชาชีพฯ (บาท)</span><br />
                <div class="input-group" role="group">
                    <input class="form-control" v-model="course_data.rate_full_pay_non" @keypress="inputOnlyNumber" @input="inputOnlyNumber"/>
                    <span class="input-group-text">บาท</span>
                </div>
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">กำหนดอัตราลงทะเบียน สำหรับชำระเต็มจำนวน โดย
                    ผู้มีใบประกอบวิชาชีพฯ (บาท)</span><br />
                <div class="input-group" role="group">
                    <input class="form-control" v-model="course_data.rate_full_pay" @keypress="inputOnlyNumber" @input="inputOnlyNumber"/>
                    <span class="input-group-text">บาท</span>
                </div>
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">กำหนดอัตราค่าประชาสัมพันธ์ เป็น % ของค่าลงทะเบียน (เปอร์เซนต์)</span><br />
                <div class="input-group" role="group">
                    <input class="form-control" v-model="course_data.advertise_percent" @keypress="inputOnlyNumber" @input="inputOnlyNumber"/>
                    <span class="input-group-text">%</span>
                </div>
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">กำหนดคุณสมบัติผู้เข้ารับ การอบรม/ทดสอบ</span><br />
                <textarea class="form-control" rows="5" v-model="course_data.register_property_text"></textarea>
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">กำหนดสิทธิประโยชน์ที่ ผู้เข้ารับ การอบรม/ทดสอบ จะได้รับ</span><br />
                <textarea class="form-control" rows="5" v-model="course_data.register_privilege_text"></textarea>
            </div>

            <div class="mt-2 form-check">
                <input class="form-check-input fs-5 text text-primary" type="checkbox" value="1" v-model="course_data.is_prefer_to_join">
                <label class="form-check-label fs-5 text text-primary">
                    ประสงค์ยื่นขอรับการรับรองการจัดอบรมและอนุมัติประกาศนียบัตร/ใบรับรอง จากสภาการแพทย์แผนไทย
                </label>
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">ลิงค์เชิญชวนเพื่อเข้าแบบสอบถาม หรือแบบสำรวจ</span><br />
                <textarea class="form-control" rows="5" v-model="course_data.content_link"></textarea>
            </div>
            <div class="mt-2">
                <span class="fs-5 text text-primary">ข้อความแสดงแทนบนลิงค์เชิญชวนเพื่อเข้าแบบสอบถาม หรือแบบสำรวจ</span><br />
                <textarea class="form-control" rows="5" v-model="course_data.content_link_text"></textarea>
            </div>
            
            <div class="mt-2">
                <span class="fs-5 text text-primary">ลิงค์แบบฟอร์มส่งการบ้าน</span><br />
                <textarea class="form-control" rows="5" v-model="course_data.course_homework_link"></textarea>
            </div>
            <div class="mt-2">
                <span class="fs-5 text text-primary">ข้อความแสดงแทนบนลิงค์แบบฟอร์มส่งการบ้าน</span><br />
                <textarea class="form-control" rows="5" v-model="course_data.course_homework_link_text"></textarea>
            </div>
            
            <div class="mt-2">
                <span class="fs-5 text text-primary">ลิงค์แบบฟอร์มส่งคำถาม</span><br />
                <textarea class="form-control" rows="5" v-model="course_data.course_question_link"></textarea>
            </div>
            <div class="mt-2">
                <span class="fs-5 text text-primary">ข้อความแสดงแทนบนแบบฟอร์มส่งคำถาม</span><br />
                <textarea class="form-control" rows="5" v-model="course_data.course_question_link_text"></textarea>
            </div>


            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="warning" size="xl" @click="updateCourse">
                        <h3 class="text-white">ปรับปรุงข้อมูล</h3>
                    </b-button>
                </div>
                <div class="col-5 d-block d-grid">
                    <b-button variant="secondary" size="xl" @click="showModal = false">
                        <h3 class="text-white">ปิด</h3>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="xl" v-model="showLearningModal" :title="'เนื้อหาอบรม : '+course_data.course_title" no-close-on-esc no-close-on-backdrop
            hide-header-close scrollable centered>            
            <div class="mt-2">
                <span class="fs-5 text text-primary">รายละเอียดโครงสร้างหลักสูตร (ชนิดไฟล์ PDF)</span><br />
                <div class="input-group" role="group">
                    <input type="file" class="form-control" @change="onFileLearningSelected" accept="application/pdf" />
                    <span class="input-group-text">File PDF Only</span>
                </div>
                <div class="mt-2" v-if="course_data.content_detail_pdf != null">
                    <a target="_blank" :href="course_data.content_detail_pdf">ไฟล์รายละเอียดโครงสร้างหลักสูตร {{ course_data.content_detail_pdf }}</a>
                </div>
            </div>
            
            <div class="mt-2">
                <span class="fs-5 text text-primary">คำอธิบาย ประกอบการอบรม/ทดสอบ </span><br />
                <div class="input-group" role="group">
                    <textarea rows="5" class="form-control" v-model="course_data.content_description_text"></textarea>
                </div>
            </div>

            <h5 class="mt-3 fs-5 text text-primary">
                ไฟล์ PDF <button class="ms-2 btn btn-xs btn-primary" type="button" @click="showPdfAddModal = true;">เพิ่มไฟล์ PDF</button>
            </h5>
            <table class="table">
                <thead>
                    <tr>
                        <th>ลำดับ</th>
                        <th>ชื่อไฟล์</th>
                        <th>URL</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-if="course_data.content_json?.pdf?.length > 0">
                    <tr v-for="(a, i) in course_data.content_json.pdf" :key="i">
                        <td>{{ i+1 }}</td>
                        <td>{{ a.file_name }}</td>
                        <td>
                            <a :href="a.file_url" target="_blank">{{ a.file_url }}</a>
                        </td>
                        <td>
                            <div class="input-group" role="group">
                                <button v-if="i > 0" class="btn btn-xs btn-secondary" type="button" @click="swapContentPdf(i, i-1)"><i class="fa fa-arrow-up"></i></button>
                                <button v-if="i < course_data.content_json.pdf.length-1" class="btn btn-xs btn-dark" type="button" @click="swapContentPdf(i, i+1)"><i class="fa fa-arrow-down"></i></button>
                                <button class="btn btn-xs btn-danger" type="button" @click="deleteContentPdf(i)">ลบ</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <h5 class="mt-3 fs-5 text text-primary">
                ไฟล์ภาพ <button class="ms-2 btn btn-xs btn-primary" type="button" @click="showImageAddModal = true;">เพิ่มไฟล์ ภาพ</button>
            </h5>
            <table class="table">
                <thead>
                    <tr>
                        <th>ลำดับ</th>
                        <th>ชื่อภาพ</th>
                        <th>ภาพ</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-if="course_data.content_json?.images?.length > 0">
                    <tr v-for="(a, i) in course_data.content_json.images" :key="i">
                        <td>{{ i+1 }}</td>
                        <td>{{ a.file_name }}</td>
                        <td>
                            <img class="img-fluid" :src="a.file_url"/>
                        </td>
                        <td>
                            <div class="input-group" role="group">
                                <button v-if="i > 0" class="btn btn-xs btn-secondary" type="button" @click="swapContentImage(i, i-1)"><i class="fa fa-arrow-up"></i></button>
                                <button v-if="i < course_data.content_json.images.length-1" class="btn btn-xs btn-dark" type="button" @click="swapContentImage(i, i+1)"><i class="fa fa-arrow-down"></i></button>
                                <button class="btn btn-xs btn-danger" type="button" @click="deleteContentImage(i)">ลบ</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <h5 class="mt-3 fs-5 text text-primary">
                ไฟล์วิดีโอ <button class="ms-2 btn btn-xs btn-primary" type="button" @click="showVideoAddModal = true;">เพิ่มไฟล์ วีดีโอ</button>
            </h5>
            <table class="table">
                <thead>
                    <tr>
                        <th>ลำดับ</th>
                        <th>ชื่อวิดีโอ</th>
                        <th>คลิป</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-if="course_data.content_json?.videos?.length > 0">
                    <tr v-for="(a, i) in course_data.content_json.videos" :key="i">
                        <td>{{ i+1 }}</td>
                        <td>{{ a.file_name }}</td>
                        <td>
                            <video class="img-fluid" :src="a.file_url" controls controlsList="nodownload" oncontextmenu="return false;"></video>
                        </td>
                        <td>
                            <div class="input-group" role="group">
                                <button v-if="i > 0" class="btn btn-xs btn-secondary" type="button" @click="swapContentVideo(i, i-1)"><i class="fa fa-arrow-up"></i></button>
                                <button v-if="i < course_data.content_json.videos.length-1" class="btn btn-xs btn-dark" type="button" @click="swapContentVideo(i, i+1)"><i class="fa fa-arrow-down"></i></button>
                                <button class="btn btn-xs btn-danger" type="button" @click="deleteContentVideo(i)">ลบ</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="warning" size="xl" @click="updateCourse">
                        <h3 class="text-white">ปรับปรุงข้อมูล</h3>
                    </b-button>
                </div>
                <div class="col-5 d-block d-grid">
                    <b-button variant="secondary" size="xl" @click="showLearningModal = false">
                        <h3 class="text-white">ปิด</h3>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="xl" v-model="showPdfAddModal" title="เพิ่มไฟล์ PDF" no-close-on-esc no-close-on-backdrop
            hide-header-close scrollable centered>            
            <div class="mt-2">
                <span class="fs-5 text text-primary">เลือกเอกสาร</span><br />
                <div class="input-group" role="group">
                    <input type="file" class="form-control" @change="onFileLearningCourseSelected" accept="application/pdf" />
                    <span class="input-group-text">File PDF Only</span>
                </div>
                <div class="mt-2 filename" v-if="newPdf.file_url != null">
                    <a target="_blank" :href="newPdf.file_url">ไฟล์ : {{ newPdf.file_url }}</a>
                </div>
            </div>
            
            <div class="mt-2">
                <span class="fs-5 text text-primary">ชื่อไฟล์</span><br />
                <div class="input-group" role="group">
                    <input type="text" class="form-control" v-model="newPdf.file_name"/>
                </div>
            </div>

            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="success" size="xl" @click="updateLearningFile">
                        <h3 class="text-white">เพิ่ม</h3>
                    </b-button>
                </div>
                <div class="col-5 d-block d-grid">
                    <b-button variant="danger" size="xl" @click="showPdfAddModal = false">
                        <h3 class="text-white">ยกเลิก</h3>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="xl" v-model="showImageAddModal" title="เพิ่มไฟล์ Image" no-close-on-esc no-close-on-backdrop
            hide-header-close scrollable centered>            
            <div class="mt-2">
                <span class="fs-5 text text-primary">เลือกไฟล์ภาพ</span><br />
                <div class="input-group" role="group">
                    <input type="file" class="form-control" @change="onImageFileLearningCourseSelected" accept="image/*" />
                    <span class="input-group-text">File Image Only</span>
                </div>
                <div class="mt-2 filename" v-if="newImage.file_url != null">
                    <img class="img-fluid" :src="newImage.file_url"/>
                </div>
            </div>
            
            <div class="mt-2">
                <span class="fs-5 text text-primary">ชื่อภาพ</span><br />
                <div class="input-group" role="group">
                    <input type="text" class="form-control" v-model="newImage.file_name"/>
                </div>
            </div>

            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="success" size="xl" @click="updateLearningImageFile">
                        <h3 class="text-white">เพิ่ม</h3>
                    </b-button>
                </div>
                <div class="col-5 d-block d-grid">
                    <b-button variant="danger" size="xl" @click="showImageAddModal = false">
                        <h3 class="text-white">ยกเลิก</h3>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="xl" v-model="showVideoAddModal" title="เพิ่มไฟล์ วีดีโอ" no-close-on-esc no-close-on-backdrop
            hide-header-close scrollable centered>            
            <div class="mt-2">
                <span class="fs-5 text text-primary">เลือกไฟล์ วิดีโอ</span><br />
                <div class="input-group" role="group">
                    <input type="file" class="form-control" @change="onVideoFileLearningCourseSelected" accept="video/mp4" />
                    <span class="input-group-text">File Video Only</span>
                </div>
                <div class="mt-2 filename" v-if="newVideo.file_url != null">
                    <video class="img-fluid" controls>
                        <source :src="newVideo.file_url" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            
            <div class="mt-2">
                <span class="fs-5 text text-primary">ชื่อวิดีโอ</span><br />
                <div class="input-group" role="group">
                    <input type="text" class="form-control" v-model="newVideo.file_name"/>
                </div>
            </div>

            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="success" size="xl" @click="updateLearningVideoFile">
                        <h3 class="text-white">เพิ่ม</h3>
                    </b-button>
                </div>
                <div class="col-5 d-block d-grid">
                    <b-button variant="danger" size="xl" @click="showVideoAddModal = false">
                        <h3 class="text-white">ยกเลิก</h3>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="xl" v-model="showExampleModal" title="จัดการข้อสอบ" no-close-on-esc no-close-on-backdrop
            hide-header-close scrollable centered>
            
            <div class="mt-2">
                <span class="fs-5 text text-primary">ระยะเวลาในการทดสอบ (นาที)</span><br />
                <div class="input-group" role="group">
                    <input type="text" class="form-control" v-model="course_data.exam_json.time_test" @keypress="inputOnlyNumber" @input="inputOnlyNumber"/>
                    <span class="input-group-text">นาที</span>
                </div>
            </div>
            
            <div class="mt-2">
                <span class="fs-5 text text-primary">จำนวนข้อที่ใช้ทดสอบ</span><br />
                <div class="input-group" role="group">
                    <input type="text" class="form-control" v-model="course_data.exam_json.amount_test" @keypress="inputOnlyNumber" @input="inputOnlyNumber"/>
                    <span class="input-group-text">ข้อ</span>
                </div>
            </div>

            <div class="mt-2">
                <span class="fs-5 text text-primary">อัตราคะแนนที่ผ่านเกณฑ์การทดสอบ ต้องได้มากกว่า (%)</span><br />
                <div class="input-group" role="group">
                    <input type="text" class="form-control" v-model="course_data.exam_json.percent_pass" @keypress="inputOnlyNumber" @input="inputOnlyNumber"/>
                    <span class="input-group-text">%</span>
                </div>
            </div>

            <h5 class="mt-3 fs-5 text text-primary">
                แบบทดสอบ <button class="ms-2 btn btn-xs btn-primary" type="button" @click="showExampleAddModal = true;">เพิ่มข้อสอบ</button>
            </h5>
            <table class="table">
                <thead>
                    <tr>
                        <th>ลำดับ</th>
                        <th>โจทย์</th>
                        <th>คำตอบที่ถูกต้อง</th>
                        <th>คำตอบที่ไม่ถูกต้อง</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-if="course_data.exam_json?.test?.length > 0">
                    <tr v-for="(a, i) in course_data.exam_json.test" :key="i">
                        <td>{{ i+1 }}</td>
                        <td>{{ a.question }}</td>
                        <td>
                            <ul>
                                <li v-for="(aa,ii) in a.correct_answers" :key="'ca'+ii"> {{ aa }}</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li v-for="(aa,ii) in a.incorrect_answers" :key="'ica'+ii"> {{ aa }}</li>
                            </ul>
                        </td>
                        <td>
                            <div class="input-group" role="group">
                                <button v-if="i > 0" class="btn btn-xs btn-secondary" type="button" @click="swapContentExample(i, i-1)"><i class="fa fa-arrow-up"></i></button>
                                <button v-if="i < course_data.exam_json.test.length-1" class="btn btn-xs btn-dark" type="button" @click="swapContentExample(i, i+1)"><i class="fa fa-arrow-down"></i></button>
                                <button class="btn btn-xs btn-danger" type="button" @click="deleteExample(i)">ลบ</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="success" size="xl" @click="updateExampleInfo">
                        <h3 class="text-white">ปรับปรุงข้อมูล</h3>
                    </b-button>
                </div>
                <div class="col-5 d-block d-grid">
                    <b-button variant="danger" size="xl" @click="showExampleModal = false">
                        <h3 class="text-white">ปิด</h3>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="xl" v-model="showExampleAddModal" title="เพิ่มข้อสอบ" no-close-on-esc no-close-on-backdrop
            hide-header-close scrollable centered>            
            <div class="mt-2">
                <span class="fs-5 text text-primary">ใส่ข้อมูล</span><br />
                <div class="input-group" role="group">
                    <span class="input-group-text">โจทย์</span>
                    <input type="text" class="form-control" v-model="newExampleTest.question"/>
                </div>
                <!-- <div class="mt-2 input-group" role="group">
                    <span class="input-group-text">ให้ใส่กรณีโจทย์มีรูป</span>
                    <input type="file" class="form-control" @change="onFileExampleImageSelected" accept="image/*" />
                    <span class="input-group-text">File Image Only</span>
                </div> -->
                <div class="mt-2 filename" v-if="newExampleTest.question_image != null">
                    <img class="img-fluid" :src="newExampleTest.question_image"/>
                </div>
            </div>
            
            <div class="mt-3">
                <span class="fs-5 text text-primary">ตัวเลือกที่ถูกต้อง</span><br />
                <div class="input-group" role="group">
                    <input type="text" class="form-control" v-model="temp_correct_choice"/>
                    <button class="btn btn-primary" type="button" @click="newExampleTest.correct_answers.push(temp_correct_choice); temp_correct_choice = null;">เพิ่ม</button>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th>ลำดับ</th>
                            <th>ข้อมูล</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="newExampleTest.correct_answers?.length > 0">
                        <tr v-for="(a, i) in newExampleTest.correct_answers" :key="i">
                            <td>{{ i+1 }}</td>
                            <td>{{ a }}</td>
                            <td>
                                <div class="input-group" role="group">
                                    <button class="btn btn-xs btn-danger" type="button" @click="deleteCorrectChoice(i)">ลบ</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <div class="mt-3">
                <span class="fs-5 text text-primary">ตัวเลือกที่ไม่ถูกต้อง</span><br />
                <div class="input-group" role="group">
                    <input type="text" class="form-control" v-model="temp_incorrect_choice"/>
                    <button class="btn btn-primary" type="button" @click="newExampleTest.incorrect_answers.push(temp_incorrect_choice); temp_incorrect_choice = null;">เพิ่ม</button>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th>ลำดับ</th>
                            <th>ข้อมูล</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="newExampleTest.incorrect_answers?.length > 0">
                        <tr v-for="(a, i) in newExampleTest.incorrect_answers" :key="i">
                            <td>{{ i+1 }}</td>
                            <td>{{ a }}</td>
                            <td>
                                <div class="input-group" role="group">
                                    <button class="btn btn-xs btn-danger" type="button" @click="deleteIncorrectChoice(i)">ลบ</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="success" size="xl" @click="updateExampleTest">
                        <h3 class="text-white">เพิ่ม</h3>
                    </b-button>
                </div>
                <div class="col-5 d-block d-grid">
                    <b-button variant="danger" size="xl" @click="showExampleAddModal = false">
                        <h3 class="text-white">ยกเลิก</h3>
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal size="xl" v-model="showLoaderModal" title="กำลังประมวลผล...." no-close-on-esc no-close-on-backdrop
            hide-header-close hide-footer centered>
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                <div class="modal-content">
                    <div class="modal-body text-black text-center">
                        <img class="mx-auto" src="@/assets/loading.svg" />
                    </div>
                </div>
            </div>
        </b-modal>

        <alert-modal :isShow="showSaveDraftCourseSuccessModal" title="บันทึกข้อมูลร่างสำเร็จ"
            text="ข้อมูลร่างถูกบันทึกแล้ว..." v-on:close="showSaveDraftCourseSuccessModal = false"></alert-modal>

        <alert-modal :isShow="showSaveDraftCourseFailedModal" title="บันทึกข้อมูลร่างผิดพลาด"
            text="เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล..." v-on:close="showSaveDraftCourseFailedModal = false"></alert-modal>

        <alert-modal :isShow="showDeleteCourseSuccessModal" title="ลบสำเร็จ" text="ข้อมูลถูกลบแล้ว..."
            v-on:close="showDeleteCourseSuccessModal = false"></alert-modal>

        <alert-modal :isShow="showDeleteCourseFailedModal" title="เกิดข้อผิดพลาดระหว่างลบข้อมูล" text="กรุณาแจ้งผู้ดูแล..."
            v-on:close="showDeleteCourseFailedModal = false"></alert-modal>
        
        <alert-modal :isShow="showCheckExampleTestDataModal" title="กรุณาตรวจสอบข้อมูลแบบทดสอบ" text="กรุณาใส่ข้อมูลให้ครบ เช่น โจทย์ต้องใส่ คำตอบที่ถูกต้อง ต้องมีอย่างน้อย 1 ตัวเลือก และเมื่อรวมกับคำตอบที่ไม่ถูกต้อง ต้องมีมากกว่าหรือเท่ากับ 4 ตัวเลือก "
            v-on:close="showCheckExampleTestDataModal = false"></alert-modal>
        
        <alert-modal :isShow="checkCourseDataModal" title="กรุณาตรวจสอบข้อมูล" text="กรุณาใส่ข้อมูลให้ครบถ้วน หรือตรวจสอบลิงค์ให้ถูกต้อง"
            v-on:close="checkCourseDataModal = false"></alert-modal>

        <confirm-modal :isShow="showDeleteCourseModal" title="ต้องการลบข้อมูลนี้ ใช่หรือไม่"
            text="หลังจากกดยืนยันแล้ว จะไม่สามารถกู้คืนข้อมูลที่ลบไปแล้วได้..." v-on:close="showDeleteCourseModal = false"
            v-on:confirm="deleteCourse"></confirm-modal>
        
            <confirm-modal :isShow="showWarningInspect" title="ยืนยันการส่งตรวจ ใช่หรือไม่"
            text="หากส่งตรวจแล้ว ในระหว่างรอตรวจสอบ จะไม่สามารถแก้ไขเนื้อหาได้ กรุณาตรวจสอบเนื้อหาที่ส่งให้สมบูรณ์ " v-on:close="showWarningInspect = false"
            v-on:confirm="confirmToSendInspect"></confirm-modal>
    </div>
</template>
